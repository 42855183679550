<template>
    <div class="container-fluid h-100 my-2">
        <div class="row justify-content-center align-items-center h-100">
            <div class="col-auto">
                <div class="box" id="login">
                    <groomy-logo />
                    <p class="text-center message_chargement mb-0 mt-4">
                        <font-awesome-icon class="spinner_home" :icon="['fas', 'spinner']" pulse /><br/>{{ progressBarText }}
                    </p>
					<p v-if="progressSynchroText" class="text-center message_chargement mb-0 mt-4">
						{{ progressSynchroText }}
					</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import Navigation from '@/mixins/Navigation.js'
import GroomyConfig from '@/assets/js/dexie/GroomyConfig'
import _uniq from 'lodash/uniq'

export default {
    name: 'LoadingScreen',
    mixins: [User, Navigation],
    props: {
        fetchRequired: {
            type: Boolean,
            default: () => (false)
        }
    },
    data: () => ({
        typeProgress: 'IAL'
    }),
    computed: {
		synchroModelsWorking() {
			return this.$store.state.sync.progressModels
		},
        progressBarText: function() {
            if(this.typeProgress) {
                return this.getTrad('progression_message.'+this.typeProgress)
            }
            return ''
		},
		progressSynchroText() {
			if(this.synchroModelsWorking.length === 0) return ''
			const modules_trad = this.synchroModelsWorking.map(model => {
				// Ne pas afficher les tables pas traduites
				const tradCode = 'progression_synchro.'+model
				const trad = this.getTrad(tradCode)
				return trad === tradCode ? '' : trad
			})
			.filter((trad) => (trad))

			return this.getTrad('progression_synchro.generic') + ' ' + _uniq(modules_trad).join(', ')
		}
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            // Se produit quand une URL loggedIn est demandée alors que le user n'est pas connecté
            if (!this.checkLocal()) {
                return
			}

            this.typeProgress = 'IAL'
			await this.initLocalConfig()

			// Il doit etre fait avant n'importe quelle requete
			// pouvant renvoyer une 401 pour token expiré
            this.typeProgress = 'IDB'
            await Promise.race(
                [this.timeoutAfter(15), this.initStorage()]
            ).then(async () => {
                this.typeProgress = 'IAL'
                await this.initRemoteConfig()

                this.typeProgress = 'APE'
                this.initBrowserPermissions()

                this.typeProgress = 'INO'
                this.initNotifications()
                
                this.typeProgress = 'INC'
                this.initNativComm()
                
                await this.initUserPermissions()

                this.redirect()
                
            }).catch((e) => {
                this.failureToast("toast.crash_navigator")
                return false
            })
        },
        async timeoutAfter(seconds) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(new Error("request timed-out"));
                }, seconds * 1000);
            });
        },
        async initLocalConfig() {
			// Migration de l'ancienne config vers la nouvelle
			// TODO: A supprimer lorsque tout le monde sera passé à GroomyConfig
			await GroomyConfig.getInstance()
			// Setup licence, bearer et langue en local storage
			await this.initSession()
		},
        async initStorage() {
			const dbExists = await this.$storage.dbExists()

			await this.$storage.init()
			await this.$sync.init()

			// Si pas de bdd, ou une synchro required non terminée proprement
			if (!dbExists || this.$sync.getLastSync('required') == 0) {
				await this.$sync.force(false)
			}
        },
		async initRemoteConfig() {
			// Setup config localstorage global
			await this.persistUserConfig()
		},
        initBrowserPermissions() {
            this.$storage.isStoragePersisted().then(isPersisted => {
                if (!isPersisted) {
                    this.$storage.persist()
                }
            })

            if ('Notification' in window) {
                window.Notification.requestPermission()
            }
        },
        initNotifications() {
            this.$firebase.messaging.init()
        },
        initNativComm() {
            this.$nativeComm.init()
        },
        redirect() {
            if (this.$route.query.redirect) {
                this.$router.replace(this.$route.query.redirect)
            } else {
                this.$router.replace({ name: 'cards'})
            }
        }
    }
}
</script>
